import React from 'react';
import axios from 'axios';
import upgradep from '../assets/upgrade.jpg';
import {
	Box,
	Typography,
	TextField,
	Button,Grid,
	useTheme,
  } from "@mui/material";
import  useRazorpay  from 'react-razorpay';
import { useSelector } from "react-redux";
import Navbar from 'scenes/navbar';
import { Api_url } from 'helper';

const PaymentButton = () => {
	const { palette } = useTheme();
	const dark = palette.neutral.dark;
	const alt = palette.background.alt;
	const [Razorpay] = useRazorpay();
	const token = useSelector((state) => state.token);
  const { _id } = useSelector((state) => state.user);
const paymentAmount='1000';
  const handlePayment = async () => {
    const r = parseInt(paymentAmount);
    try {
      const response = await axios.post(`${Api_url}/users/${_id}/payment`, {
        amount: r,
      },{
		headers: {
		  Authorization: `Bearer ${token}`, // Replace with your actual token
		},
	  });
console.log(response.data,'response')
      const options = {
        key: 'rzp_test_Jtpvppi2NrnCCK',
        amount: response.data.amount,
        currency: response.data.currency,
        name: 'Connect Pro',
        description: 'Hubhawks Private Limited',
        order_id: response.data.order_id,
        handler: async function (response, e, data) {
          console.log(JSON.stringify(data));
		  console.log(JSON.stringify(data));
          if (response.razorpay_payment_id) {
            try {
              await axios.put(`${Api_url}/users/${_id}/status`, {
                status: 'pro',
              }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              alert('Payment successful! Your status is now "pro".');
            } catch (error) {
              console.log(error);
              alert('Error updating user status');
            }
          } else {
            alert('Payment failed');
          }
        },
        prefill: {
          name: 'John Doe',
          email: 'johndoe@example.com',
        },
      };

      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.log(error);
      alert('Error processing payment');
    }
  };

	return (<div>
	  <Navbar />
    <Box sx={{
		
		
	  padding: '16px',
	  marginBottom: '16px',
	  borderRadius: '4px'
		}}>
			<Grid container lg={11}  backgroundColor={alt}sx={{margin:'auto',border: '1px solid #ccc',height:'84vh'}}>
				<Grid container lg={6} sx={{justifyContent:'center',alignItems:'center'}}>
					<img src={upgradep} style={{width:'300px',height:'300px',marginTop:'10px',marginBottom:'10px'}} alt="login" />
				</Grid>
				<Grid container lg={6} sx={{alignItems:'center',justifyContent:'center'}}>
				<Grid item lg={10} xs={10} >
          <div >
            <Typography variant="h4"  color={dark} gutterBottom>
              Upgrade to Pro
            </Typography>
            <Typography variant="body1"  color={dark} paragraph>
              Unlock premium features and enjoy the full potential of ConnectPro.
            </Typography>
            <Typography variant="body1"  color={dark} paragraph>
              Benefits of Pro:
              <ul>
                <li>Advanced analytics</li>
                <li>Priority customer support</li>
                <li>Unlimited connections</li>
              </ul>
            </Typography>
          
							<Button  variant="contained" color="primary"onClick={handlePayment} sx={{marginBottom:"30px"}}>Upgrade to Pro</Button>
          </div>
        </Grid>
				
				</Grid>
			</Grid>
      
	  </Box>
	  </div>
  );
};

export default PaymentButton;
