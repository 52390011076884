import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Button, useTheme,TextField,Grid } from '@mui/material';
import Navbar from 'scenes/navbar';

const API_KEYS = [
  '8a5e2842b3msh0aee99a5f032bcfp13e17cjsnbfb6bc1b392d',
  'ecf9668977msh3bc1b6bd4103d87p16a851jsnd9f4c59c3c24',
];

const JobSearchComponent = () => {
	const [jobs, setJobs] = useState([]);
	const { palette } = useTheme();
	const dark = palette.neutral.dark;
	const alt = palette.background.alt;
	const background = palette.background.jobs;
  const [searchQuery, setSearchQuery] = useState('React Js Developer, India');
	const [apiKeyIndex, setApiKeyIndex] = useState(0);
	
  const [shouldFetchData, setShouldFetchData] = useState(true);
const fetchData = async () => {
      try {
        const apiKey = API_KEYS[apiKeyIndex];
        const response = await axios.get('https://jsearch.p.rapidapi.com/search', {
          params: {
            query: `${searchQuery},India`,
            page: '1',
				num_pages: '1',
				num_pages: '10', // Step 4
				remote_jobs_only: 'true',
				job_requirements: 'under_3_years_experience,no_experience'
          },
          headers: {
            'X-RapidAPI-Key': apiKey,
            'X-RapidAPI-Host': 'jsearch.p.rapidapi.com'
          }
        });

		  if (response.data && response.data.data) {
			console.log(response.data.data)
          setJobs(response.data.data);
        } else {
          // If limit exhausted for current key, switch to the next key
          if (apiKeyIndex + 1 < API_KEYS.length) {
            setApiKeyIndex(apiKeyIndex + 1);
          } else {
            console.error('All API keys have been exhausted.');
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

 
	useEffect(() => {
		if (shouldFetchData) {
		  fetchData();
		  setShouldFetchData(false); // Turn off automatic trigger after first load
		}
	  }, [shouldFetchData]);
  return (
	  <div>
		  <Navbar />
		  <Grid container lg={10} sx={{marginTop:'10px',justifyContent:'center',alignItems:'center'}}>
		  <TextField
        label="Search Jobs"
        variant="outlined"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        style={{ marginBottom: '16px',width:"40%" }}
      />
      <Button
        variant="contained"
				  color="primary"
				  style={{height:"8vh",marginBottom:"16px"}}
        onClick={() => setShouldFetchData(true)} // Manually trigger API
      >
        Search
      </Button>
		  </Grid>
		
      {jobs.map((job) => (
        <Box
          key={job.id}
          sx={{width:"82%",margin:'auto',
			  border: '1px solid #ccc',
			  backgroundColor: {background},
            padding: '16px',
            marginBottom: '16px',
            borderRadius: '4px'
          }}
		  >
			  <img src={job.employer_logo} style={{width:'150px',height:'150px'}} alt='logo Not Found'/>
		  <Typography variant="h5" color={dark}>{job.employer_name}</Typography>
			  <Typography variant="h6" color={dark}>{job.job_title}</Typography>
			  <Typography variant="subtitle1" color={dark}>Remote-work:{job.job_is_remote}</Typography>
			  <Typography variant="subtitle1" color={dark}>{job.location}{job.job_state}</Typography>
			  <Typography variant='subtitle2' color={dark}>
			  {job.job_description.split('\n').map((line, index) => (
              <div key={index} color={dark}>{line}</div>
            ))}
          </Typography>
			  <Typography variant='subtitle2' color={dark}>Source-{job.job_publisher}</Typography>
			  <Typography variant='subtitle2' color={dark}>{job.job_required_experience.required_experience_in_months}months</Typography>  
          {/* Add more job information fields here */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(job.job_apply_link, '_blank')}
          >
            Apply
          </Button>
        </Box>
      ))}
    </div>
  );
};

export default JobSearchComponent;
